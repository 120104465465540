<template>
  <div class="refund-page">
    <template v-if="userinfo && userinfo.recentRecords">
      <div class="record-info" >
        <p class="record-header">最近24小时付款订单</p>
        <template v-for="item in userinfo.recentRecords">
          <div class="record-item" :key="item.tradeno">
            <div class="left">
              <span class="label">金额:{{item.fee}}元</span>
              <span class="time">{{item.updatetime | formatTime}}</span>
            </div>
            <van-button size="mini" round  type="primary" @click="refund(item.tradeno)">退捐</van-button>
          </div>
        </template>
        <van-empty v-if="userinfo.recentRecords.length === 0" description="暂无最近订单"></van-empty>
      </div>
    </template>
    <div class="warn-tip em" v-if="userinfo.recentRecords.length > 0">
        退捐失败，请联系QQ：3556044645，承诺7x24小时内响应处理<br>
      </div>
  </div>

</template>

<script>
import { Empty } from 'vant'
import { refundOrder } from '../api/pay'

export default {
  components: {
    [Empty.name]: Empty,
  },
  data () {
    return {
    }
  },
 
  methods: {
    refund (tradeno) { 
      refundOrder({ order_id: tradeno })
        .then(res => { 
          if (res.code === 0) {
            this.$toast.success('退款成功，将在一小时内到账')
          } else { 
            this.$toast.fail('出现未知错误')
          }
        }).finally(() => {
          this.updateUserinfo()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.refund-page {
  padding-top: 40px;
  .record-info {
    margin: -5px 0 15px;
    padding: 15px 10px;
    border-radius: 4px;
    .record-header {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .record-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 10px;
      background-color: rgba($warning-color, 0.1);
      .left {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;
        .label {
          padding-right: 6px;
        }
      }
      .van-button {
        padding: 0 10px;
        flex: none;
      }
    }
  }
  .warn-tip {
    margin: 20px;
    padding: 5px;
    color: rgb(128, 82, 50);
    font-size: 12px;
    border-radius: 2px;
    background-color: #fffbe8;
    text-align: justify;
    border: 1px solid rgba(73, 33, 5, 0.2);
  }
}
</style>